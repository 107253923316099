<template>
  <div class="user-permissions-wrapper">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userInfo"])
  },
  created() {
    if (this.userInfo.user.role !== "admin") {
      this.$router.push({ path: "/partner" });
    }
  }
};
</script>
